import React, { useState, useEffect } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import Select from "react-select"

import { useVoxLegals } from "hooks"

import Input from "components/Input"
import FormLegals from "components/FormLegals"
import Button from "components/Button"

import { useLocale } from "context/locale"

import selectStyles from "utils/selectStyles"

const Form = ({
  formType,
  investment,
  withSelect,
  rmMessage,
  parkingStorage,
}) => {
  const { t } = useLocale()

  const { selectLegal, acceptedLegals } = useVoxLegals()

  const [city, setCity] = useState("")
  const formID =
    formType === "sale" && investment === "Stacja Kopernik"
      ? "3452"
      : formType === "sale" && city === "Radom"
      ? "7469"
      : formType === "sale" && city === "Gliwice"
      ? "7503"
      : formType === "sale"
      ? "756"
      : "755"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)
  const [legalSecond, setLegalSecond] = useState(false)
  const [legalThird, setLegalThird] = useState(false)

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  useEffect(() => {
    selectLegal(["marketing", "mail", "phone", "sms"], legalFirst && legalThird)
    selectLegal("statement", legalSecond)
  }, [selectLegal, legalFirst, legalSecond, legalThird])

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("investment", investment ? investment : "")
    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("city", city)
    formData.set("parkingStorage", parkingStorage)
    formData.set("pageurl", location)

    if (acceptedLegals) {
      Object.entries(acceptedLegals).forEach(([key, value]) => {
        formData.set(key, value)
      })
    }

    axios({
      method: "post",
      url: `https://trust-investment.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        isBrowser &&
          window.Monitor.TrackForm("trustForm", [
            { "yl-investment": investment },
            { "yl-name": name },
            { "yl-email": email },
            { "yl-phone": phone },
            { "yl-message": message },
            { "yl-city": city },
            { "yl-site_url": location },
            { "yl-legal-1": legalFirst },
            { "yl-legal-2": legalSecond },
            { "yl-legal-3": legalThird },
          ])

        console.log("Submit success")
        navigate(t("/kontakt-potwierdzenie/"))
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={formSubmit} id="trustForm">
      {withSelect && (
        <div className="select-wrapper">
          <Select
            placeholder={t("wybierz miasto*")}
            styles={selectStyles}
            defaultValue={""}
            options={[
              { value: "Kielce", label: t("Kielce") },
              { value: "Częstochowa", label: t("Częstochowa") },
              { value: "Radom", label: t("Radom") },
              { value: "Gliwice", label: t("Gliwice") },
            ]}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            onChange={e => setCity(e.value)}
          />
        </div>
      )}
      <Input
        placeholder={`${t("imię i nazwisko")}*`}
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={e => setName(e.target.value)}
        required
      />
      <Input
        placeholder={`${t("telefon")}*`}
        type="phone"
        id="phone"
        name="phone"
        value={phone}
        onChange={e => setPhone(e.target.value)}
        required
      />
      <Input
        placeholder="e-mail*"
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      {!rmMessage && (
        <Input
          placeholder={
            investment
              ? t("proszę o kontakt w sprawie inwestycji")
              : t("treść wiadomości")
          }
          type="textarea"
          id="message"
          name="message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          rows="9"
        />
      )}
      <div className="contact-form__action">
        <FormLegals
          legalFirst={legalFirst}
          setLegalFirst={setLegalFirst}
          legalSecond={legalSecond}
          setLegalSecond={setLegalSecond}
          legalThird={legalThird}
          setLegalThird={setLegalThird}
        />
        <Button
          className="button button--circle"
          type="submit"
          disabled={!legalFirst || !legalSecond || !legalThird || send}
        >
          {send === true ? `${t("Wysyłanie")}...` : t("Wyślij")}
        </Button>
      </div>
    </form>
  )
}

export default Form
