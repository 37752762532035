import "./styles.scss"

import React from "react"

import Checkbox from "components/Checkbox"

const FormLegals = ({
  legalFirst,
  setLegalFirst,
  legalSecond,
  setLegalSecond,
  legalThird,
  setLegalThird,
}) => {
  return (
    <div className="form-legals">
      <Checkbox
        name="legalFirst"
        checked={legalFirst && legalSecond && legalThird}
        onChange={() => {
          if (legalFirst && legalSecond && legalThird) {
            setLegalFirst(false)
            setLegalSecond(false)
            setLegalThird(false)
          } else {
            setLegalFirst(true)
            setLegalSecond(true)
            setLegalThird(true)
          }
        }}
      >
        {legalFirst && legalSecond && legalThird
          ? "Odznacz wszystkie poniższe zgody"
          : "Zaznacz wszystkie poniższe zgody"}
      </Checkbox>
      <Checkbox
        name="legalFirst"
        checked={legalFirst}
        more="<p>Wyrażam zgodę na otrzymywanie od Trust Investment S.A. z siedzibą w  dotyczących oferty Spółek z Grupy Trust. Wykaz Spółek znajduje się na stronie internetowej pod adresem: <a href='https://trustinvestment.pl/deweloper/spolki-trust-investment/' target='_blank'>https://trustinvestment.pl/deweloper/spolki-trust-investment/</a>. Wyrażam zgodę na kontakt:</p><ul><li>za pomocą telekomunikacyjnych urządzeń końcowych dla celów marketingu bezpośredniego (telefon)*;</li><li>w formie wiadomości e-mail*;</li><li>w formie wiadomości SMS*.</li></ul>"
        required
        onChange={() => setLegalFirst(!legalFirst)}
      >
        Wyrażam zgodę na wszystkie poniższe formy kontaktu marketingowego.
        Wyrażam zgodę na poszczególne formy kontaktu:&nbsp;...
      </Checkbox>

      <Checkbox
        name="legalFirst"
        checked={legalSecond}
        more="dotyczących preferencji zakupowych i innych danych wskazanych w treści wiadomości oraz w zakresie informacji o mojej aktywności na stronie internetowej przez Trust Investment S.A. z siedzibą w Kielcach (ul. Robotnica 1, Kielce) w celu marketingu bezpośredniego produktów i usług. Zostałam/em poinformowana/y, że w dowolnym momencie mam prawo wycofać zgodę na przetwarzanie moich danych osobowych. Wycofanie zgody na przetwarzanie danych osobowych nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. Pełna treść informacji dotyczących przetwarzania danych osobowych dostępna jest pod <a href='https://trustinvestment.pl/polityka-prywatnosci/' target='_blank' rel='noreferrer nofollow'>linkiem</a>."
        required
        onChange={() => setLegalSecond(!legalSecond)}
      >
        Wyrażam zgodę na przetwarzanie danych osobowych w postaci imienia i
        nazwiska, adresu e-mail, numeru telefonu, informacji&nbsp;...
      </Checkbox>

      <div className="form-legals__info">
        <p>
          W celu wycofania powyższych zgód wyślij maila na adres{" "}
          <a href="mailto:sprzedaz@trustinvestment.pl">
            sprzedaz@trustinvestment.pl
          </a>
          .
        </p>
      </div>

      <Checkbox
        name="legalFirst"
        checked={legalThird}
        more="<p>z siedzibą w Kielcach (ul. Robotnica 1, Kielce) informacji handlowej o ofercie współpracujących z Trust Investment S.A. podmiotów zewnętrznych. Lista Podmiotów znajduje się na stronie internetowej pod adresem <a href='https://trustinvestment.pl/deweloper/spolki-trust-investment/' target='_blank'>https://trustinvestment.pl/deweloper/spolki-trust-investment/</a>. Wyrażam zgodę na kontakt:</p><ul><li>za pomocą telekomunikacyjnych urządzeń końcowych dla celów marketingu bezpośredniego (telefon);</li><li>w formie wiadomości e-mail.</li></ul>"
        required
        onChange={() => setLegalThird(!legalThird)}
      >
        Wyrażam zgodę na otrzymywanie od Trust Investment S.A.&nbsp;...
      </Checkbox>
    </div>
  )
}

export default FormLegals
